.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.bg-black-custom{
  background-color: #6d0091 !important;
}

.transactionTableImage > div > img {
  max-height: 50px !important;
  max-width: 50px !important;
}

.text-ellipses{
  max-width: 150px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #ebebed;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.dashed-line {
  border-bottom: 1px solid #000;
  height: 100px; /* Adjust height as needed */
  width: 50%;
  margin-bottom: 10px; /* Adjust margin as needed */
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
