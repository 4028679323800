body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  zoom: 0.8;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.btn-primary{
  background-color: #6d0091 !important;
  border-color: #6d0091 !important;
}

.btn-outline-primary{border:1px solid #6d0091 !important; color: #6d0091 !important;}
.btn-outline-primary:hover{background: #6d0091 !important; color: #fff !important;}
.text-danger{color:red !important;}
.text-success{color: green !important;}
.text-primary{color: #6d0091 !important;}
.list-action i{font-size: 20px;}
.bg-primary{background: #6d0091 !important;}

.page-item.active .page-link {background-color: #6d0091 !important; border-color: #6d0091 !important;color: #fff !important;}
.page-item .page-link {border-color: #6d0091 !important;color: #6d0091 !important;}
.page-item .page-link:focus {border-color: #6d0091 !important;outline: #6d0091 !important;}


.card-total-sale i{font-size: 35px; color: #6d0091;}
.csv{color: #0173C6;}
.excel{color: #216F44;}
.pdf{color: #F72115;}

.btn-light{height: 45px ;background: #6d0091 !important; border: 1px solid #6d0091 ;font-size: 14px ;color: #fff !important ;border-radius: 10px ;box-shadow: none ; text-align: left !important;}
.btn-light:hover{height: 45px ;background: #6d0091 !important ;border: 1px solid #6d0091 ;font-size: 14px ; color: #fff !important;}
.btn-light.dropdown-toggle:focus {box-shadow: inset 0 3px 5px rgba(255, 255, 255, 0.125),0 0 0 .2rem rgba(150, 150, 150, 0.5);}
.show > .btn-light.dropdown-toggle {color: #fff !important ; background-color: #6d0091 !important; border: 1px solid #6d0091 ;}
.btn-light.dropdown-toggle:focus { box-shadow: inset 0 3px 5px rgba(255, 255, 255, 0.125),0 0 0 .2rem rgba(255, 255, 255, 0.5) !important;}

.highcharts-point .highcharts-color-0{background-color: #216F44 !important;}
.print-logo{width: 130px;}
.tenant-print label{font-weight: 600; width: 200px; }
.list-print{border-top: 1px solid #6d0091;}
.print-card{border: 1px solid #6d0091;}
.top p{border-bottom-right-radius: 50px; border-bottom-left-radius: 50px;}


@media print {
  .print-button {
      display: none;
  }
}

@media print {

.print-table {
    width: 100% !important ;
}
  .print-layout {
      width: 100%;
      margin-top: 0; /* Set your desired top margin for printing */
  }

  .print-logo {
      margin: 0; /* Set your desired margin for the logo */
  }

  .print-button {
      display: none; /* Hide the print button when printing */
  }

  /* .print-logo {
     width: 500px;
  } */
}
.term{border-bottom-right-radius: 50px;border-top-right-radius: 50px;}
.term-condition ol li {font-size: 20px;margin-bottom: 10px;}
.term-condition{width: 75%;}

.header-text{font-size: 22px; font-weight: 900 !important;}

.report-icons{font-size: 30px; margin-right: 20px;}
.apexcharts-bar-area{
  fill: #6d0091 !important;
}

.forget-password{
  display: flex;
  align-items: center;
  min-height: 100vh;
  justify-content: center;
}
.reset-logo img{
  width: 150px;
}

.revenue-d label{ width: 300px;}

.wrapper-menu{color: #fff !important;}

.logo-title {color: white !important;}